.sidebar-navigation ul li {
  cursor: pointer;
}

.app-sidebar--content::-webkit-scrollbar {
  width: 0;
}

.cursor-pointer {
  cursor: pointer;
}
.sidebar-open-close {
  /* display: none !important; */
  opacity: 0;
  transition: all 0.5s ease-out 100ms !important;
  background-color: #fff !important;
  z-index: 1;
  box-shadow: 0px 0px 25px #ccc;
}
.app-sidebar--header .app-sidebar-logo .app-sidebar-logo .py-2 {
  position: relative;
}
.close-sidebar-logo-image {
  opacity: 1;
  transition: all 0.5s ease-out 100ms !important;
  position: absolute;
  left: -7px;
  top: 17px;
}
.open-sidebar-logo-image {
  opacity: 0;
  position: absolute;
  left: -80px;
  top: -43px;
  transition: all 0.5s ease-out 100ms !important;
}
.sidebar-navigation ul li a {
  display: flex;
  gap: 5px;
}
@media screen and (min-width: 1100px) {
  /* .app-sidebar:hover .sidebar-open-close {
    opacity: 1;
  }
  .sidebar-open-close {
    position: absolute !important;
    right: -20px !important;
    top: 80px !important;
  }
  .app-sidebar {
    transition: all 1s ease-out 100ms;
    border-radius: 0 45px 0 0;
  }
  .app-sidebar--header {
    border-radius: 0 45px 0 0;
  }
  .sidebar-with-4rem .app-sidebar {
    width: 4rem !important;
    border-radius: 0 45px 45px 0;
  }
  .app-sidebar-logo img {
    width: 144px !important;
    margin: 0;
    transition: all 1s ease-out 100ms;
  }
  .sidebar-with-4rem .app-sidebar-logo img {
    width: 60px !important;
    margin: 0 0 0 -24px;
  }
  .sidebar-with-4rem
  .sidebar-with-4rem .app-content {
    padding-left: 5rem;
  }
  .app-main .app-content {
    padding-left: 15rem;
    transition: all 1s ease-out 100ms;
  }
  .sidebar-with-4rem + .app-main .app-content {
    padding-left: 5rem;
  }
  .app-main .app-header {
    left: 14rem;
    transition: all 1s ease-out 100ms;
  }
  .sidebar-with-4rem + .app-main .app-header {
    left: 2.6rem;
  }
  .sidebar-with-4rem img.close-sidebar-logo-image {
    opacity: 1;
    width: 50px !important;
    margin-left: -19px !important;
  }
  .sidebar-with-4rem .open-sidebar-logo-image {
    opacity: 0;
  } */

  .app-sidebar {
    width: 4rem;
    border-radius: 0 45px 0px 0;
    transition: all 0.5s ease-out 100ms;
  }
  .app-sidebar--header {
    border-radius: 0 45px 0 0;
    transition: all 0.5s ease-out 100ms;
  }
  .app-sidebar:hover,
  .sidebar-with-4rem .app-sidebar {
    width: 15rem;
    /* border-radius: 0 0px 0px 0; */
  }
  /* .app-sidebar:hover .app-sidebar--header {
    border-radius: 0 0px 0 0;
  } */
  .app-sidebar-logo img {
    width: 50px !important;
    margin: 0 0 0 -18px;
    transition: all 0.5s ease-out 100ms;
  }
  .app-sidebar:hover .app-sidebar-logo img,
  .sidebar-with-4rem .app-sidebar-logo img {
    width: 144px !important;
    margin: 0;
  }
  .app-sidebar-fixed .app-content {
    padding-left: 5rem;
  }
  .app-main .app-content {
    transition: all 0.5s ease-out 100ms;
  }
  .main-sidebar-content:hover + .app-main .app-content,
  .main-sidebar-content.sidebar-with-4rem + .app-main .app-content {
    padding-left: 15rem;
  }
  .main-sidebar-content:hover + .app-main .app-header,
  .main-sidebar-content.sidebar-with-4rem + .app-main .app-header {
    left: 14rem;
  }
  .main-sidebar-content + .app-main .app-header {
    left: 2.6rem;
    transition: all 0.5s ease-out 100ms;
  }

  .app-sidebar:hover .open-sidebar-logo-image,
  .sidebar-with-4rem .open-sidebar-logo-image {
    opacity: 1;
  }
  .app-sidebar:hover .close-sidebar-logo-image,
  .sidebar-with-4rem .close-sidebar-logo-image {
    opacity: 0;
  }
  .app-sidebar:hover .sidebar-open-close {
    opacity: 1;
  }
  .sidebar-open-close {
    position: absolute !important;
    right: -20px !important;
    top: 80px !important;
  }
  .collapse-sidebar-open-close {
    position: absolute !important;
    right: 5px;
    top: 15px;
  }
}
@media screen and (max-width: 1099px) {
  .open-sidebar-logo-image {
    opacity: 1;
  }
  .close-sidebar-logo-image {
    opacity: 0;
    display: none;
  }
}
.app-sidebar--light .sidebar-navigation ul li > a.active > .sidebar-menu-img,
.app-sidebar--light .sidebar-navigation ul li > a:hover > .sidebar-menu-img {
}
.app-sidebar--light .sidebar-navigation ul li > a > .sidebar-menu-img {
  width: 25px;
}
