.ptage {
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  font-size: 14px;
  padding: 4px 0 4px 0;
  background: #c3e4ef;
  font-weight: 500;
  margin: 5px;
  cursor: grab;
}
.cursor {
  cursor: pointer;
}
.comonDrag {
  width: 24%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.container1 {
  /* background-color: #1d75be; */
  border-radius: 10px;
  margin: 5px;
  text-align: center;
  cursor: grab;
}
.heading {
  font-weight: 600;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #bfbfbf;
  height: 30px;
  display: flex;
  justify-content: center;
  padding-top: 4px;
  background: #525252;
  color: white;
}
.button {
  text-align: end;
  margin-top: 15px;
}
@media only screen and (max-width: 1320px) {
  .comonDrag {
    width: 32%;
  }
}
@media only screen and (max-width: 845px) {
  .comonDrag {
    width: 48%;
  }
}
@media only screen and (max-width: 510px) {
  .comonDrag {
    width: 98%;
  }
  .button {
    text-align: center;
    margin-top: 15px;
  }
}
.checkboxcashentry {
  min-width: 10px !important;
  padding: 0 !important;
}
.trcashEntry .MuiTableCell-root {
  /* padding: 4px !important; */
}
