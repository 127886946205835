.cashView {
  display: flex;
  justify-content: space-between;
  background: #0e3a5f;
  color: white;
  padding: 6px;
}
.cashView1 {
  display: flex;
  justify-content: center;
  background: #0e3a5f;
  font-weight: 500;
  padding: 6px;
}
.tableforriskscroe table {
  border-collapse: collapse;
  width: 100%;
}

.tableforriskscroe th,
.tableforriskscroe td {
  padding: 8px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.tableforriskscroe tr:hover {
  background-color: #d7ecd9;
}
.poploader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  left: 45%;
  border: 3px solid;
  border-color: #0e3a5f #0e3a5f transparent transparent;
  box-sizing: border-box;
  animation: poprotation 1s linear infinite;
}
.poploader::after,
.poploader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #000000 #000000;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: poprotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.poploader::before {
  width: 32px;
  height: 32px;
  border-color: #0e3a5f #0e3a5f transparent transparent;
  animation: poprotation 1.5s linear infinite;
}

@keyframes poprotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes poprotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
