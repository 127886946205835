.main-heading {
    font-weight: 700!important;
    width: 100%!important;
    margin-bottom: 1.5rem!important;
    font-size: 1.7rem;
    line-height: 1.2;
    font-family: cairo,sans-serif;
}
.checkbox-section {
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
}
.action-button-section {
    display: flex;
    justify-content: center;
}
.action-button-section button {
    width: 30%;
}
.input-section {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}
.input-section.qr-code-section {
    justify-content: space-evenly;
}
.input-section.qr-code-section img {
    width: 300px;
    height: auto;
}

.page {
    position: absolute;
    background-color: black;
    padding: 0;
    width: 100%;
    height: 100%;  
  }
  
  .container {
    margin: 2rem auto;
    width: 60%;
    height: 90%; 
    border-radius: 5px;
    overflow: auto;
    padding: 15px;
  }
  
  .dnd-list {
    display: flex;
    flex-direction: row;
    height: 35px;
    margin-bottom: 10px;
    box-shadow: 0px 1px 4px grey;
    border-radius: 10px;
    padding: 5px;
    width: 100%;
  }
  
  .input-item {
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: move;
    width: 100%;
  }
  
  .over {
    transform: scale(1.01);
  }

  .dnd-list.over {
    border: 1px dashed #000;
  }
  
  .addButton {
    display: flex;
    align-items: center;
    margin: auto;
    line-height: 26px;
    border: none;
    color: black;
    border-radius: 50%;
    font-size: 24px;
  }
  
  .delButton {
    display: flex;
    align-items: center;
    color: darkgrey;
    margin-left: 10px;
    height: 37px;
    width: 30px;
    font-size: 20px;
    font-weight: bolder;
    cursor: pointer;
  }
  .createMenuItemButtonEnd {
    display: flex;
    justify-content: end;
  }
  .menu-edit-button {
    color: red !important;
  }