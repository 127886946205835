.main-heading {
    font-weight: 700!important;
    width: 100%!important;
    margin-bottom: 1.5rem!important;
    font-size: 1.7rem;
    line-height: 1.2;
    font-family: cairo,sans-serif;
}
.notification-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.notification-section .notification-element {
    display: flex;
    justify-content: space-between;
    background-color: #6a85c8;
    padding: 20px;
    color: #fff;
    border-radius: 10px;
    align-items: center;
}
.notification-section .notification-element:hover {
    box-shadow: 0px 0px 10px #8c8c8c;
}