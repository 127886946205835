.main-heading {
    font-weight: 700 !important;
    width: 100% !important;
    margin-bottom: 1.5rem !important;
    font-size: 1.7rem;
    line-height: 1.2;
    font-family: cairo, sans-serif;
}

.actionButtonGroup {
    display: flex;
    gap: 5px;
}

.btn-edit {
    color: #2371ed !important;
    background-color: #deeafc !important;
}

.btn-close {
    color: #f83245 !important;
    background-color: #fee0e3 !important;
}

.create-role-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #c4c4c4;
}

.permission-table-header,
.permission-table-body {
    display: grid;
    grid-template-columns: 30% 10% 10% 10% 10% 10% 10% 10%;
    text-align: center;
    box-shadow: 0px 0px 10px #ccc;
    padding: 15px;
    border-radius: 15px;
}

.permission-table-header {
    background-color: #e7edfb;
    box-shadow: none;
    border-radius: 15px 15px 0 0;
}

.permission-table-header label {
    font-weight: 600;
    color: #6ea2f6;
}

.permission-radio-button {
    height: 22px;
    width: 22px;
    border-radius: 50% !important;
    border: 2px solid #757575;
    appearance: none;
    cursor: pointer;
    position: relative;
}

.permission-radio-button:disabled {
    border: 2px solid #d4d4d4;
}

.permission-radio-button:checked {
    background-color: #6ea2f6;
    position: relative;
    border: none;
}
.permission-radio-button:checked::after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 5px;
    opacity: 1;
    position: absolute;
    top: 7px;
    transform: rotate(-45deg);
    width: 13px;
}

.permission-table {
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-x: auto;
}

.create-role-content-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.createRoleButton {
    display: flex;
    justify-content: center;
}

.leads-section {
    grid-template-columns: 10% 6% 6% 6% 6% 6% 6% 6% 6% 10% 10% 10% 10%;
}

.mt5-section {
    grid-template-columns: 10% 6% 6% 6% 6% 6% 6% 6% 6% 10% 10% 10% 10%;
    font-size: 14px;
}

.dashboard-section {
    grid-template-columns: 20% 10% 10% 10% 10% 10% 10% 10% 10%;
    font-size: 14px;
}
.client-leade-role-section .client-lead-section {
    grid-template-columns: 30% 10% 10% 10% 10% 10%;
}
.client-kyc-role-section .client-lead-section {
    grid-template-columns: 30% 10% 10% 12% 12% 10%;
}
.permission-table .site-role-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 5px;
    min-width: 690px;
}
.permission-table .leads-role-section, .permission-table .mt5-role-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
    min-width: 1100px;
    padding: 5px;
}
.permission-table .client-leade-role-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
    min-width: 660px;
    padding: 5px;
}
.permission-table .dashboard-role-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
    min-width: 760px;
    padding: 5px;
}
.permission-table .client-kyc-role-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
    min-width: 640px;
    padding: 5px;
}
.main-menu-section {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    padding: 5px;
    border-radius: 10px;
    list-style: none;
    user-select: none;
}
.main-menu-section div {
    display: flex;
    align-items: center;
    gap: 10px;
}
.main-menu-section a {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px;
    flex-wrap: wrap;
}
.role-management-section {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
}
.sub-menu-section {
    list-style: none;
    width: 100%;
    padding: 0 65px;
}
.sub-menu-section li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    user-select: none;
}
.sub-menu-section .sub-menu.permission {
    padding: 0px 30px;
}
.sub-menu-section .sub-menu.permission .sub-menu-title {
    font-weight: 600;
    color: green;
}