.report-botton {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.report-table td {
  min-width: 106px;
  border-bottom: 1px solid #d9d9d9;

  padding: 5px 8px;
}
.report-table th {
  border-bottom: 1px solid #d9d9d9;
  color: #1d75be !important;
  padding: 5px 8px;

  text-transform: capitalize;
}
.report-table {
  width: 100%;
}
.border-right-teble {
  border-right: 1px solid #d9d9d9;
}
.report-tfoot-table td {
  text-align: end;
}
.report-noData-table {
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reportLoderChild {
  display: flex;
  height: 50px;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.ltdBold td {
  font-weight: 700;
}
