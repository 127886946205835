.dropZoneOverlay,
.FileUpload {
  width: 159px;
  height: 90px;
}

.dropZoneOverlay {
  border: dotted 2px;
  /* font-family: cursive; */
  position: absolute;
  font-weight: 700;
  top: 0px;
  text-align: center;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border-color: #cfd9e0;
  justify-content: center;
}

.FileUpload {
  opacity: 0;
  position: relative;
  z-index: 1;
  /* border: 2px dotted black; */
}
.perviewImageSize {
  max-width: 159px;
  max-height: 90px;
}
.perviewImageSize img {
  max-width: 159px;
  max-height: 90px;
}
.imageClose {
  position: absolute;
  left: 94%;
  color: white;
  background: #a03535;
  border-radius: 15px;
  font-size: 16px !important;
  top: -7px;
}
